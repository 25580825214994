import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Select } from "antd";
import ContentSection from "./contentStyle";
import Blog from "./blogBlock";

const { Option } = Select;

const Content = () => {
  const initaiSetting = {
    numberToShow: 12,
    loadMoreNumber: 6,
  };

  const [category, setCategory] = useState("latest");
  const [numberToShow, setNumberToShow] = useState(initaiSetting.numberToShow);

  const {
    allSanityBlogs: { edges },
  } = useStaticQuery(graphql`
    query {
      allSanityBlogs(sort: { order: DESC, fields: publishdate }) {
        edges {
          node {
            ...sanityBlogsPreview
          }
        }
      }
    }
  `);

  const renderedBlogs = edges
    .map(({ node: blog }) => blog)
    .sort((a, b) => b.publishdate - a.publishdate)
    .filter(blog => blog.category === category || category === "latest");

  return (
    <ContentSection>
      <div className="top-bar">
        <Select
          defaultValue={category}
          onChange={value => {
            setCategory(value);
            setNumberToShow(initaiSetting.numberToShow);
          }}
          dropdownMatchSelectWidth={false}
        >
          <Option value="latest">Latest</Option>
          <Option value="Home Loans">Home Loans</Option>
          <Option value="Business Loans">Business Loans</Option>
          <Option value="Development Finance">Development Finance</Option>
          <Option value="Commercial Property Finance">Commercial Property Finance</Option>
          <Option value="Property Investment Lending">Property Investment Lending</Option>
          <Option value="Other">Other</Option>
        </Select>
      </div>

      <div className="blogs">
        {renderedBlogs.slice(0, numberToShow).map(blog => (
          <Blog key={blog.slug.current} blog={blog} />
        ))}
      </div>

      {renderedBlogs.length >= numberToShow && (
        <button
          className="theme-btn block red"
          onClick={() => setNumberToShow(pre => pre + initaiSetting.loadMoreNumber)}
        >
          Load More
        </button>
      )}
    </ContentSection>
  );
};

export default Content;
