import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SubHeader from "../shared/subHeader"

const blogsHeader = () => {
  const {
    sanityBlogsPage: { title },
  } = useStaticQuery(graphql`
    query {
      sanityBlogsPage {
        title
      }
    }
  `)

  return <SubHeader title={title} />
}

export default blogsHeader
