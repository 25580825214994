import styled from "styled-components"

export default styled.section`
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5rem;
  max-width: 1400px;

  @media only screen and (max-width: 976px) {
    width: 80%;
  }
  
   .top-bar {
    position: sticky;
    top: var(--nav-height);
    padding: 2rem 0;
    z-index: 2;
    background-color: var(--white);
  }

  .blogs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6rem 3rem;

    @media only screen and (max-width: 976px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 4rem 2rem;
    }
  }

  .theme-btn {
    margin: 0rem auto;
    margin-top: 5rem;
    &:focus {
      outline: none;
    }
  }
`