import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO, { seoDataFormat } from "../shared/seo"

const BlogsSEO = () => {
  const {
    sanityBlogsPage: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityBlogsPage {
        seo {
          slug {
            current
          }
          pageTitle
          pageKeyWords
          pageDescription
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default BlogsSEO
