import React from 'react'
import BlogsHeader from "../components/blogs/header"
import BlogsContent from "../components/blogs/content"
import BlogsSEO from '../components/blogs/blogsSEO'

const Blogs = () => {
  return (
    <div className="subpage-container">
      <BlogsSEO />
      <BlogsHeader />
      <BlogsContent />
    </div>
  )
}

export default Blogs
